"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OSNotificationPermission = void 0;
var OSNotificationPermission;
(function (OSNotificationPermission) {
    OSNotificationPermission[OSNotificationPermission["NotDetermined"] = 0] = "NotDetermined";
    OSNotificationPermission[OSNotificationPermission["Denied"] = 1] = "Denied";
    OSNotificationPermission[OSNotificationPermission["Authorized"] = 2] = "Authorized";
    OSNotificationPermission[OSNotificationPermission["Provisional"] = 3] = "Provisional";
    OSNotificationPermission[OSNotificationPermission["Ephemeral"] = 4] = "Ephemeral";
})(OSNotificationPermission = exports.OSNotificationPermission || (exports.OSNotificationPermission = {}));
